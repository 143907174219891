<template>
<div>
	<!-- <v-app> -->
		<v-container>
			<v-row align="center" dense>
				<h3 class='text-h5 grey--text text--darken-2'>アップロード</h3>
			</v-row>
			
			<br>
			<v-file-input color="indigo darken-2" v-bind:disabled="isUploading" accept=".csv" v-model="inputFile" label="CSVファイル選択" @change="onCsvChange"></v-file-input>
			<!-- <v-btn v-bind:disabled="isNotSelected" class='text-button white--text' color="indigo darken-2" @click="post">アップロード</v-btn> -->
			
			<v-dialog
				v-model="showUploadDiaglog"
				persistent
				max-width="290"
			>
				<template v-slot:activator="{ on, attrs }">
					<v-btn 
						v-bind:disabled="isNotSelected || isUploading"
						v-bind="attrs"
						class='text-button white--text'
						color="indigo darken-2"
						v-on="on">
						<v-icon>mdi-upload</v-icon>
						<pre> </pre>
						アップロード
					</v-btn>
				</template>
				<v-card>
					<v-card-title class="text-h5">
						アップロード
					</v-card-title>
					<v-card-text class="font-weight-bold">選択したCSVファイルをアップロードします。</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							@click="showUploadDiaglog = false"
							depressed
						>
							キャンセル
						</v-btn>
						<v-btn
							color="primary"
							depressed
							@click="post"
							class="font-weight-bold"
						>
							アップロード
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>

			<div v-if="uploadSucceeded">
				<br>
				<v-alert
					dense
					outlined
					text
					type="success"
				>アップロードが正常に完了しました</v-alert>
			</div>
			<div v-if="uploadSucceeded == false">
				<br>
				<v-alert
					dense
					outlined
					text
					type="error"
				>アップロードに失敗しました。(Internal Server Error)</v-alert>
			</div>


			<div v-if="csvError.length > 0">
				<br>
				<v-alert
					v-for="err in csvError" :key="err.err"
						dense
						outlined
						text
						type="error"
					> {{ err.err }} </v-alert>
			</div>

			<div v-if="!isNotSelected && csvError.length == 0">
				<br>
				<v-alert
					dense
					outlined
					text
					type="info"
				> アップロード出来ます。 </v-alert>
			</div>

			<v-dialog
				v-model="isUploading"
				hide-overlay
				persistent
				width="300"
				>
				<v-card
					color="indigo darken-2"
					dark
				>
					<v-card-text>
					アップロードしています。
					<v-progress-linear
						indeterminate
						color="white"
						class="mb-0"
					></v-progress-linear>
					</v-card-text>
				</v-card>
			</v-dialog>
			<br>
			<br>

		</v-container>
	<!-- </v-app> -->
	</div>
</template>

<script>
import Encoding from 'encoding-japanese'
export default {
	name: 'Upload',
	data () {
		return {
			isNotSelected: true,
			isUploading: false,
			fileName: '',
			drawer: null,
			csvFile: null,
			inputFile: null,
			showUploadDiaglog: false,
			uploadSucceeded: undefined,
			csvError: [],
			coltypes: {
				'構造物種類':"STRING",
				'構造物名':"STRING",
				'路線名':"STRING",
				'所在地':"STRING",
				'管理者名':"STRING",
				'対象部材':"STRING",
				'架設年次':"INT",
				'交通量(台/12h)':"INT",
				'大型車混入率(%)':"FLOAT",
				'橋長(m)':"FLOAT",
				'幅員(m)':"FLOAT",
				'桁本数':"INT",
				'材料区分(RC・PC)':"STRING",
				'路下条件':"STRING",
				'代替路の有無':"STRING",
				'自専道or一般道':"STRING",
				'緊急輸送道路':"STRING",
				'占用物件(名称)':"STRING",
				'桁形式区分':"STRING",
				'等級':"STRING",
				'有効幅員(m)':"FLOAT",
				'橋面積(m2)':"FLOAT",
				'総径間数':"INT",
				'平面形状':"STRING",
				'平面線形(m)':"FLOAT",
				'縦断勾配(%)':"FLOAT",
				'設計基準':"STRING",
				'設計活荷重':"STRING",
				'設計震度':"FLOAT",
				'主桁材料区分':"STRING",
				'上部工構造形式':"STRING",
				'路面位置':"STRING",
				'床版材料区分':"STRING",
				'床版厚(mm)':"FLOAT",
				'床版形式':"STRING",
				'防水工有無':"STRING",
				'材料区分':"STRING",
				'下部構造高(m)':"FLOAT",
				'下部構造形式':"STRING",
				'排水施設':"STRING",
				'遮音壁の有無':"STRING",
				'高欄種別':"STRING",
				'緯度':"FLOAT",
				'経度':"FLOAT",
				'主損傷':"STRING",
				'他損傷1':"STRING",
				'他損傷2':"STRING",
				'主原因':"STRING",
				'他原因1':"STRING",
				'他原因2':"STRING",
				'補修工法種別候補1':"STRING",
				'補修工法種別候補2':"STRING",
				'補修工法種別候補3':"STRING",
				'一般補修工法名主':"STRING",
				'一般補修工法名従1':"STRING",
				'一般補修工法名従2':"STRING",
				'離岸距離':"FLOAT",
				'年降水量':"FLOAT",
				'ASR地質':"INT",
				'0°C以下日':"INT",
				'画像名':"STRING",
				'損傷原因の調査必要有無':"STRING",
				'補修工法種別候補の調査必要有無':"STRING",
				'一般補修工法の調査必要有無':"STRING",
				'損傷原因の判定元':'STRING',
				'補修工法種別候補の判定元':'STRING',
				'一般補修工法名の判定元':'STRING',
				'画像撮影日時':'DATETIME',
				'データ更新日時':'DATETIME'
			}
		}
	},
	methods: {
		// CSVチェック
		async checkCsv(file) {
			// check
			const fr = new FileReader()
			// fr.readAsText(file)
			// fr.readAsText(file, 'Shift-JIS')
			let self = this
			const result = new Promise(function(resolve) {
				fr.onload = (e) => {

					var array = new Uint8Array(e.target.result);
					// console.log(array)

					const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
					// console.log(bom)
					if (array[0] == bom[0] && array[1] == bom[1] && array[2] == bom[2]) {
						array = array.slice(3)
					}

					const encode = Encoding.detect(array);
					const unicodeArray = Encoding.convert(array, {
						to: 'UNICODE',
						from: encode
					});
					const csvText = Encoding.codeToString(unicodeArray);

					// console.log(csvText)

					var errors = []
					// var linesTmp = csvText.replace('\r\n', '\n').replace('\r', '\n')
					// var linesTmp = csvText.replace('\r\n', '\n')
					var linesTmp = csvText.replace('\r', '\n').replace('\n\n', '\n')
					let lines = linesTmp.split('\n')
					// let lines = csvText.split("\n");
					
					// check headers
					// header数チェック
					let headers = lines[0].split(',')
					let correct_headers = Object.keys(self.coltypes)
					if (headers.length != correct_headers.length) {
						errors.push({"err":"CSVの列数が合っていません"})
						resolve(errors)
						return
					}
					var ngcols = []
					for (let header of headers) {
						if (!correct_headers.includes(header.replace('\n', '').replace('\r', ''))) {
							ngcols.push(header)
						}
					}

					if ( ngcols.length > 0) {
						errors.push({"err":`正しくない列名が存在します。(${ngcols.join(',')})`})
						resolve(errors)
						return
					}

					lines.shift();
					var wrongCols = []
					// if (lines.includes('')) {
					// 	console.log(lines.indexOf(''))
					// 	lines.splice(lines.indexOf(''),1)
					// 	console.log(lines.indexOf(''))
					// }
					// while (lines.includes('') || lines.includes('\r')) {
					// 	lines.splice(lines.indexOf(''),1)
					// 	lines.splice(lines.indexOf('\r'),1)
					// }

					if (lines[lines.length - 1] == '') {
						lines.pop()
					}
					
					for (let i = 0; i < lines.length; i++) {
						let line = lines[i].split(",");
						for (let ii = 0; ii < correct_headers.length; ii++) {

							// if (!line[ii]) {continue}
							const content = line[ii].replace('\n', '').replace('\r', '')
							if (content == '') {
								continue;
							}
							if ( self.coltypes[correct_headers[ii]] == "INT" ) {
								if (isNaN(Number(content))) {
									// console.log(`${correct_headers[ii]}:${content}`)
									if (!wrongCols.includes(correct_headers[ii])) {
										wrongCols.push(correct_headers[ii])
									}
								}
							} else if ( self.coltypes[correct_headers[ii]] == "FLOAT" ) {
								if (isNaN(parseFloat(content))) {
									// console.log(`${correct_headers[ii]}:${content}`)
									if (!wrongCols.includes(correct_headers[ii])) {
										wrongCols.push(correct_headers[ii]);
									}
								}
							} else if ( self.coltypes[correct_headers[ii]] == "DATETIME" ) {
								// console.log(`content: ${content}`)
								let date = new Date(content);

								if (!date.getTime()) {
									if (!wrongCols.includes(correct_headers[ii])) {
										wrongCols.push(correct_headers[ii]);
									}
								}
							}
						}
					}
					if ( wrongCols.length > 0) {
						errors.push({"err":`次の列に正しくない値が存在します。(${wrongCols.join(',')})`})
						resolve(errors)
						return
					}
					// console.log(`isError: ${errors}`)
					resolve(errors)
				}
				// fr.readAsText(file, 'Shift-JIS')
				// fr.readAsText(file, 'UTF-8')
				fr.readAsArrayBuffer(file);
			});
			return result
		},

		// CSVファイル選択時の処理
		async onCsvChange(file) {
			this.uploadSucceeded = undefined;
			if (file !== undefined && file !== null) {
				if (file.name.lastIndexOf('.') <= 0) {
					this.isNotSelected = true;
					return
				}
				// console.log(file) // form-data形式
				
				const check = await this.checkCsv(file);
				
				if (check != '') {
					this.csvError = check;
					return;
				}
				const fr = new FileReader()
				// fr2.readAsDataURL(file)
				// fr.addEventListener('load', () => {
				// 	this.csvFile = fr.result // base64形式
				// 	console.log("read file OK");
				// });
				fr.onload = (e) => {
					var array = new Uint8Array(e.target.result);
					const encode = Encoding.detect(array);
					const unicodeArray = Encoding.convert(array, {
						to: 'UNICODE',
						from: encode
					});
					const csvText = Encoding.codeToString(unicodeArray);
					this.csvFile = csvText;
				}
				// fr.readAsText(file, 'Shift_JIS')
				fr.readAsArrayBuffer(file);
				this.isNotSelected = false;
				this.csvError = [];
			} else {
				this.isNotSelected = true;
				this.csvError = [];
				return;
			}
		},
		// CSVファイルアップロード
		async post () {
			this.showUploadDiaglog = false
			// console.log(this.account)
			await this.getAccessToken(this.$store.state.account)

			this.isUploading = true
			const data = { data : this.csvFile};
			await this.$axios.post('/upload', data, {
				headers: {
					Authorization: "Bearer " + this.$store.state.accessToken
				}
				})
				.then(() => {
					this.isUploading = false
					this.uploadSucceeded = true
					this.isNotSelected = true
					this.inputFile = null
					this.$store.dispatch("setCurrentGotListState", 0);
				})
				.catch( err => {
					console.log("exception: " + err);
					this.isUploading = false
					this.uploadSucceeded = false
					this.isNotSelected = true
					this.inputFile = null
					return
				});
			// setTimeout(() => {
			// 	this.isUploading = false
			// 	this.uploadSucceeded = true
			// 	this.isNotSelected = true
			// 	this.inputFile = null
			// }, 3000)
		},
	}
}
</script>

<style lang="scss" scoped>
  input[type='text'] {
    margin-bottom: 20px;
    padding: 10px;
  }

</style>